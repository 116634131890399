.catering-list-details {
  margin-bottom: 25px;

  .couses-images {
    img {
      height: 220px;
      width: 100%;
      border-radius: 4px 4px 0 0;
      @media all and (max-width: 575px) {
        height: 100% !important;
      }
    }
  }
}
