.offline-class-list {
  .offline-class-list-details {
    margin-bottom: 25px;

    .offline-class-image {
      img {
        height: 255px;
        width: 100%;
        border-radius: 4px 4px 0px 0px;

        @media all and (max-width: 575px) {
          height: 100% !important;
        }
      }
    }

    .offline-class-list-details-inner {
      padding: 24px;
      display: inline-block;
      width: 100%;
      background: #fff;
      min-height: 170px;

      .offline-class-title {
        h1 {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-medium;
          font-size: $font-size-18;
          color: $color-dark;
          margin-bottom: 10px;

          @media (max-width: 404px) {
            font-size: $font-size-20;
          }
        }
      }

      .offline-course-title-discription {
        p {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-16;
          color: $color-dark;
          margin-bottom: 10px;
        }
      }

      .discounted-and-original-price {
        display: flex;
        align-items: center;

        p {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-medium;
          font-size: $font-size-16;
          color: $color-dark;
          margin-bottom: 0px;
        }

        span {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-16;
          color: $color-medium-dark;
          margin-bottom: 0px;
          text-decoration: line-through;
          margin-left: 10px;
        }
      }
    }
  }
}

.offline-page-section {
  width: 85%;
}

@media (max-width: 767px) {
  .offline-page-section {
    width: 100% !important;
  }
}
