.login {
  width: 100%;
  display: inline-block;

  .login-image {
    img {
      width: 100%;
      object-fit: cover;
      height: 100vh;
    }
  }

  .login-details-inner {
    margin: 0 auto;
    width: 100%;
  }

  .login-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 62%;
    margin: 0 auto;

    .login-title {
      h1 {
        font-family: $font-family-outfit;
        font-style: normal;
        font-weight: $font-weight-bold;
        font-size: $font-size-34;
        color: $color-dark;
        margin: 20px 0 16px;
      }

      p {
        font-family: $font-family-outfit;
        font-style: normal;
        font-weight: $font-weight-regular;
        font-size: $font-size-16;
        color: $color-dark;
        margin-bottom: 40px;
      }
    }

    .login-form {
      .form-label {
        font-family: $font-family-outfit;
        font-style: normal;
        font-weight: $font-weight-regular;
        font-size: $font-size-14;
        color: #434e5b;
      }

      .form-group {
        margin-bottom: 20px;
        position: relative;

        .form-control {
          background: #ffffff;
          border: 1px solid #d0d3d6;
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-14;
          color: $color-dark;
          height: 45px;
          border-radius: 10px;
          outline: none;
          &:focus {
            outline: none;
            box-shadow: none;
          }

          &::placeholder {
            color: #a1a7ad;
          }
        }

        button {
          background-color: transparent;
          border: none;
          position: absolute;
          right: 10px;
          top: 40px;
        }
      }

      .forgot-pass {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;

        label {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-14;
          color: $color-dark;
        }

        button {
          background-color: transparent;
          border: none;
          padding: 0;
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-semi-bold;
          font-size: $font-size-14;
          color: $color-red;
        }

        .form-check-input:checked {
          background-color: #ffffff;
          border-color: #ae6eff;
        }

        .form-check-input:checked[type="checkbox"] {
          background-image: url("../../../public/images/check.svg");
          background-size: auto;
        }

        .form-check-input {
          width: 20px;
          height: 20px;
          border-color: #ae6eff;
          margin-right: 10px;
        }
      }

      .login-button {
        margin-top: 20px;

        button {
          background: #e05955;
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-semi-bold;
          font-size: $font-size-16;
          color: #ffffff;
          padding: 13px;
          width: 100%;
          border: none;
          border-radius: 10px;
          margin-top: 20px;
        }
      }

      .create-account {
        margin-top: 20px;
        display: flex;

        p {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-16;
          color: $color-dark;
          margin: 0;
        }

        button {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-16;
          color: $color-red;
          background-color: transparent;
          border: none;
          padding: 0;
          margin-left: 5px;
        }
      }
    }
  }

  .login-logo {
    width: 100%;
    text-align: center;
  }
}

.register-title {
  h1 {
    margin-bottom: 0px !important;
  }
}

.register-form {
  margin-bottom: 40px;

  .create-account {
    margin-top: 12px !important;
  }
}

.login-form {
  .verify-otp {
    display: flex;
    align-items: center;
    text-align: center;

    input {
      width: 45px;
      height: 52px;
      border-radius: 5px;
      background: #f4f4f4;
      border: none;
      text-align: center;
      margin-right: 16px;
      font-family: $font-family-outfit;
      font-style: normal;
      font-weight: $font-weight-bold;
      font-size: 28px;
      color: #1d1d1d;
      outline: none;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .eyes-button {
    svg {
      path {
        color: #727a84;
      }
    }
  }
}

.verify-code {
  margin-top: 25px;

  p {
    font-family: $font-family-outfit;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1d1d1d;
    margin: 0;

    span {
      color: $color-red;
      margin-left: 5px;
    }
  }
}

.resend-button {
  margin-top: 25px;
  button {
    background-color: transparent;
    border: none;
    font-family: $font-family-outfit;
    font-style: normal;
    font-weight: $font-weight-medium;
    font-size: $font-size-14;
    line-height: 20px;
    color: #4980ff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      margin-right: 10px;
    }
  }
}

.login .login-details .login-form .form-group input[type="password"] {
  font-weight: $font-weight-semi-bold;
  &::placeholder {
    font-size: $font-size-14 !important;
    font-weight: $font-weight-regular !important;
  }
}
