.edit-gallery {
  background: #f0f2f5;
  .edit-gallery-inner {
    width: 76%;
    @media all and (max-width: 1626px) {
      width: 80%;
    }
    @media all and (max-width: 1506px) {
      width: 95%;
    }
    .gallery-image {
      margin-bottom: 22px;
      height: 290px;
      width: 290px;
      position: relative;
      img {
        height: 100%;
        width: 100%;
      }
      input {
        position: absolute;
        top: 20px;
        left: 20px;
      }
    }
  }
}
