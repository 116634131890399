.dashboard {
  .dashboard-inner {
    display: flex;
    justify-content: center;
    .add-category-details {
      width: 50%;
    }
    .today-and-yesterday-earning {
      padding: 24px 16px;
      display: flex;
      align-items: center;
      width: 100%;
      .today-earning {
        text-align: center;
        width: 50%;
        background: #fafafa;
        .only-border {
          border-right: 2px solid #d9d9d9;
          margin-top: 15px;
          margin-bottom: 15px;
          .star-image {
            img {
              margin-top: 0px;
            }
          }
          .today-earning-show {
            p {
              font-family: $font-family-outfit;
              font-style: normal;
              font-weight: $font-weight-bold;
              font-size: $font-size-22;
              color: $color-gray;
              margin-bottom: 10px;
              margin-top: 15px;
            }
          }
          .today-title {
            span {
              font-family: $font-family-outfit;
              font-style: normal;
              font-weight: $font-weight-regular;
              font-size: $font-size-14;
              color: $color-light-gray;
            }
          }
        }
      }
    }
  }
}

.hide-border {
  border: none !important;
}

.recent-courses-and-monthly-revenue {
  margin-top: 30px;

  .position-datepicker {
    position: relative;
  }
  .date-image {
    img {
      position: absolute;
      right: 7px;
      top: 11px;
    }
  }
}
.recent-courses-table {
  padding: 18px;
  overflow: auto;
  white-space: nowrap;
}
.recent-courses-table-inner {
  border: 1px solid #f0f0f0;
  width: 100%;
}
.recent-table-heading {
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
}
.recent-table-heading th {
  padding: 18px;
  font-family: $font-family-outfit;
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-14;
  color: $color-gray;
}
.recent-table-details {
  border-bottom: 1px solid #f0f0f0;
}
.recent-table-details td {
  padding: 18px;
  font-family: $font-family-outfit;
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: $font-size-14;
  color: $color-gray;
}
.progressbar-section {
  padding: 25px;
}
.progressbar-section .week-title label {
  font-family: $font-family-outfit;
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-16;
  color: $color-gray;
}

.progressbar {
  width: 100%;
}
.progressbar input {
  width: 100%;
}
.range-and-percentage {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.percentage p {
  font-family: $font-family-outfit;
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: $font-size-18;
  color: $color-light-gray;
  margin-bottom: 0px;
  margin-left: 15px;
}

.progressbar-inner {
  margin-top: 18px;
  margin-bottom: 25px;
}

// input[type='range'] {
//   -webkit-appearance: none !important;
//   height:12px;
//   background: #F5F5F5;
//   border-radius: 10px;
// }
// input[type='range']::-webkit-slider-thumb {
//   -webkit-appearance: none !important;
//   background:blue;
//   height:10px;
//   width:10px;
//   display: none;
// }

.view-courses-button a {
  text-decoration: none;
  border: 2px solid #e1d9d9;
  border-radius: 6px;
  padding: 8px 10px;
  color: $color-gray;
}
.action-button {
  background: transparent;
  color: black;
  border: none;
  &:hover {
    background: transparent;
  }
}

.active-inactive-radio-button {
  text-align: center;
  max-width: 105px;
}

.active-inactive-radio-button .form-switch .form-check-input {
  width: 35px;
  height: 18px;
}

.active-inactive-radio-button .form-check-input:checked {
  background-color: #e05955;
  border-color: #e05955;
}

.active-inactive-radio-button .form-check-input:focus {
  border-color: #e05955;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(254 94 145 / 25%);
}

.padding-4 {
  padding-left: 4rem !important;
}

//.react-datepicker__navigation--next {
//  background-image: url("../../../public/images/black-arrow.svg");
//  background-repeat: no-repeat;
//  right: 10px;
//  width: 15px;
//}
