header {
  width: 100%;

  .admin-header {
    width: -webkit-fill-available;
    position: fixed;
    top: 0px;
    box-shadow: 0 0 10px 0;
    z-index: 1;

    .admin-title {
      background: #ffffff;
      box-shadow: inset 0px -1px 0px #f0f0f0;
      padding: 13px 28px;
      text-align: right;

      .logout-button {
        .dropdown-item.active {
          color: #1d1d1d;
        }

        svg {
          margin-right: 9px;
          margin-top: -3px;
        }

        button {
          background: white;
          color: black;
          border: none;
        }
        .dropdown-menu {
          width: 115px;
          min-width: 115px;
          padding: 2px 0;
          a:hover {
            background: none;
          }
        }
      }
      .dropdown-item {
        font-family: $font-family-outfit;
        font-weight: $font-weight-regular;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 0px;
      }
    }

    .courses-list-section {
      padding: 16px 25px;
      background: #ffffff;

      .courses-list-title {
        display: flex;
        align-items: center;
        p {
          font-family: $font-family-outfit;
          font-size: $font-size-14;
          font-weight: $font-weight-regular;
          color: rgba(0, 0, 0, 0.45);
          margin-bottom: 0px;
          margin-right: 10px;
        }
        span {
          font-family: $font-family-outfit;
          font-size: $font-size-14;
          font-weight: $font-weight-regular;
          color: #000000;
          margin-bottom: 0px;
        }
      }

      .courses-list-title-two {
        p {
          font-family: $font-family-outfit;
          font-size: $font-size-20;
          font-weight: $font-weight-medium;
          color: rgba(0, 0, 0, 0.85);
          margin-top: 15px;
          margin-bottom: 13px;
        }
      }
    }
  }
}
