.admin-sidebar {
  width: 256px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  height: 100vh;
  z-index: 9;
  display: block;

  @media (max-width: 980px) {
    .admin-sidebar {
      display: none;
      width: 256px;
    }
  }

  .food-couture-logo-sidebar {
    justify-content: center;
    display: flex;
    align-items: center;
    padding-top: 28px;
    padding-bottom: 30px;
    img {
      height: 100px;
      width: 130px;
    }
  }

  .sidebar-accrodion-pages {
    .accordion {
      .accordion-item {
        border: none;

        &:first-of-type {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }

        a {
          text-decoration: none;
        }

        .accordion-button {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          color: #262626;
          font-family: $font-family-outfit;
          font-size: $font-size-14;
          font-weight: $font-weight-regular;
          background: #ffffff;
          border: none;
          text-decoration: none !important;
          &:not(.collapsed) {
            box-shadow: none;
            background: #fafafa;
            color: #e05955;
          }

          &:not(.collapsed) .sub-dropdown-title {
            box-shadow: none;
            background: #fafafa;
            color: #e05955;
            svg {
              fill: red !important;
            }
          }

          &::after {
            content: "";
            margin-left: auto;
            background-image: url("../../../public/images/black-arrow.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto;
            height: 6px;
            width: 9px;
          }

          &:not(.collapsed)::after {
            background-image: url("../../../public/images/pink-arrow.svg");
            transform: rotate(0deg) !important;
          }

          &:focus {
            box-shadow: none;
          }

          svg {
            margin-right: 12px;
          }
        }

        .accordion-body {
          padding: 0px;
        }
      }
    }
  }

  .content {
    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      background: #fafafa;
      float: left;
      width: 100%;

      li {
        a {
          color: #262626;
          font-family: $font-family-outfit;
          font-size: $font-size-14;
          font-weight: $font-weight-regular;
          text-decoration: none;
          padding: 15px 48px;
          float: left;
          width: 100%;
        }

        .active {
          color: #e05955;
        }
      }
    }
  }
}

.dashboard-active {
  color: #e05955;
}

.sub-dropdown-title {
  margin-bottom: 0px;
  margin-left: 30px;
  color: #262626;
  font-family: $font-family-outfit;
  font-size: $font-size-14;
  font-weight: $font-weight-regular;
}

.common-page {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.header-and-main-content {
  width: calc(100% - 256px);
  margin-left: 256px;

  @media all and (max-width: 980px) {
    width: 100%;
    margin-left: 0;
  }
}

@media all and (max-width: 980px) {
  .d-blocks {
    display: block;
  }

  .d-nones {
    display: none;
  }
}

.responsive-sidebar {
  position: absolute;
  left: 4px;
  top: 0px;
  cursor: pointer;
  display: none;

  @media (max-width: 980px) {
    display: block;
    z-index: 999;
  }

  img {
    padding: 10px;
  }
}

.responsive-sidebar1 {
  position: absolute;
  left: 220px;
  top: 0px;
  z-index: 999;
  cursor: pointer;

  img {
    padding: 10px;
  }
}

.sidebar-main {
  position: fixed;
  z-index: 9;

  .backdrop-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
}

.swal2-styled.swal2-confirm {
  background: #e05955 !important;
}

.dashboard {
  .accordion-button {
    &::after {
      background-image: none;
      display: none;
    }
  }
}

.swal2-title {
  font-size: 26px !important;
}

.swal2-popup {
  width: 27em !important;
  padding: 0px 0 10px !important;
}

.custom-height {
  max-height: 700px !important;
  overflow-y: scroll !important;
}
