.pagination {
  justify-content: end;
}

.label-pagination {
  color: #434e5b;
  font-size: 17px;
  font-weight: 400;
  margin-top: 2px;
}

.page-link {
  border: none;
  padding: 0px 0px;
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px !important;
  margin: 0px 8px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #606161;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}

.page-link:hover {
  color: #606161 !important;
  background: rgb(247, 247, 247) !important;
}

.page-item .active {
  width: 32px !important;
  height: 32px !important;
  margin: 0px 8px !important;
  transition: -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s,
    transform 0.25s ease 0s !important;
  outline: none !important;
  background: #e05955 !important;
  color: #fff !important;
}

.page-item .active:hover {
  color: #fff !important;
  background: #e05955 !important;
  cursor: default;
}

.page-link-dot {
  color: #606161;
  font-size: 14px;
  font-weight: 400;
  margin: -5px 8px !important;
}

.disabled {
  pointer-events: none !important;
  color: rgb(221, 221, 221) !important;
  cursor: not-allowed !important;
}
