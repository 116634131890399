.add-recipes {
  background: #f0f2f5;
  .add-category-details {
    background: #ffffff;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    width: 100%;

    .add-category-and-close-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      width: 100%;
      border-bottom: 1px solid #f0f0f0;

      .add-category-title {
        h1 {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-semi-bold;
          font-size: $font-size-16;
          color: $color-gray;
          margin-bottom: 0px;
        }
      }

      .close-button {
        button {
          border: none;
          background: transparent;
          height: 10px;
          padding: 0px;
        }

        img {
          height: 10px;
          width: 10px;
        }
      }
    }

    .category-input-feild {
      padding: 10px 0px;

      .category-input-feild-inner {
        label {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-14;
          color: $color-gray;
          margin-bottom: 10px;
        }

        input {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-14;
          width: 100%;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          padding: 8px;

          &::placeholder {
            font-family: $font-family-outfit;
            font-style: normal;
            font-weight: $font-weight-regular;
            font-size: $font-size-14;
            color: $color-light-gray;
          }
        }

        textarea {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-14;
          width: 100%;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          padding: 8px;
          height: 60px;

          &::placeholder {
            font-family: $font-family-outfit;
            font-style: normal;
            font-weight: $font-weight-regular;
            font-size: $font-size-14;
            color: $color-light-gray;
          }
        }

        .form-select {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-14;
          color: $color-light-gray;
          padding: 8px;
          background-color: #fff;
          border: 1px solid #d9d9d9;
          border-radius: 0px;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          appearance: none;

          &:focus {
            box-shadow: none;
          }
        }

        .upload-video {
          position: relative;
          height: 150px;
          width: 100%;
          z-index: 999;
          opacity: 0;
          border: none;
        }
      }
    }

    .reset-and-done-button {
      display: flex;
      align-items: center;
      justify-content: end;
      padding: 12px 18px;
      width: 100%;
      margin-top: 20px;
      border-top: 1px solid #f0f0f0;

      .reset-button {
        button {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-14;
          color: $color-light-gray;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          background: transparent;
          margin-right: 8px;
          padding: 6px 16px;
        }
      }

      .done-button {
        button {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-14;
          color: $color-white;
          background: #e05955;
          border-radius: 2px;
          border: none;
          padding: 6px 16px;
        }
      }
    }

    .upload-video-div-design {
      padding: 25px 0px;
      position: absolute;
      top: 50px;
      text-align: center;
      border: 1px dashed #d9d9d9;
      border-radius: 2px;
      background: #f5f5f5;
      height: 166px;
      width: 100%;

      .upload-image {
        svg {
          color: #e05955;
        }
      }
      .click-or-drag-to-upload-title {
        p {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-16;
          color: $color-gray;
          margin-bottom: 4px;
          margin-top: 22px;
        }
      }

      .bulk-upload-title {
        p {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-16;
          color: $color-light-gray;
          margin-bottom: 0px;
        }
      }

      .progress-bar-upload {
        width: 50%;
        display: inline-block;

        .progress-bar {
          background-color: #e05955 !important;
        }

        @media all and (max-width: 430px) {
          width: 100%;
        }
        @media all and (max-width: 370px) {
          width: 100%;
        }
      }

      @media all and (max-width: 430px) {
        height: 193px;
      }

      @media all and (max-width: 370px) {
        height: 214px;
      }
    }

    .only-padding {
      padding: 0 24px;

      @media all and (max-width: 430px) {
        .button-div .reset-and-done-button {
          margin-top: 60px !important;
        }
      }
    }

    .recipe-uploaded-video-details {
      padding: 24px;

      .recipe-uploaded-video-image {
        img {
          width: 100%;
        }
      }

      .uploaded-video-title {
        p {
          word-break: break-word;
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-bold;
          font-size: $font-size-16;
          color: $color-gray;
          margin-bottom: 10px;
          margin-top: 10px;
        }
      }

      .uploaded-video-discription {
        p {
          word-wrap: break-word;
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-14;
          color: $color-gray;
          margin-bottom: 10px;
        }
      }

      .multiple-image-show {
        display: flex;
        justify-content: start;
        align-items: end;
        margin-bottom: 13px;
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 13px;

        .icon-section {
          display: flex;
          justify-content: space-between;
          align-items: end;

          svg {
            color: #5f5f5f;
          }
        }

        .ml-auto {
          margin-left: auto;
        }
        img {
          width: 63px;
          height: 42px;
        }

        p {
          word-wrap: break-word;
          font-family: "Outfit";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #434343;
          // margin-bottom: 11px;
          align-items: center;
          display: flex;
          justify-content: center;
          padding-left: 11px;
        }
      }

      .promo-video {
        img {
          width: 39px;
        }
      }
    }
  }
}

.border-class {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 13px;
}

.only-online-video-padding {
  padding: 0 0 0 24px;

  @media all and (max-width: 430px) {
    .button-div .reset-and-done-button {
      margin-top: 60px !important;
    }
  }

  .scroll-video {
    overflow-y: scroll;
    max-height: calc(100vh - 160px);
    overflow-x: hidden;

    .box {
      border-bottom: 1px solid #d9d9d9;
      padding: 17px 20px 17px 0;

      .delete-img {
        display: flex;
        margin-left: auto;
        cursor: pointer;
      }
    }
  }
}
.scroll-video {
  overflow-y: scroll;
  max-height: calc(100vh - 160px);
  overflow-x: hidden;
}

.scroll-video::-webkit-scrollbar {
  width: 8px;
}

.scroll-video::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.scroll-video::-webkit-scrollbar-thumb {
  background: #e16b67;
  border-radius: 10px;
}

.scroll-video::-webkit-scrollbar-thumb:hover {
  background: #1d1d1d;
}

.only-positon-new {
  position: relative;
  margin-bottom: 20px;

  @media all and (max-width: 430px) {
    margin-bottom: 45px;
  }
  @media all and (max-width: 370px) {
    margin-bottom: 67px;
  }
}

.recipe-recommended-block-list {
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    li {
      word-wrap: break-word;
      font-family: $font-family-outfit;
      font-style: normal;
      font-weight: $font-weight-regular;
      font-size: $font-size-16;
      color: $color-gray;
      margin-bottom: 16px;
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 16px;
    }
  }
}

.separator {
  display: flex;
  align-items: center;
}

.separator .line {
  height: 2px;
  flex: 1;
  background-color: #f0f0f0;
}

.separator p {
  padding: 0 1rem;
  margin-bottom: 0px !important ;
}
