.couses-list,
.amazon-products-list,
.offline-class-list {
  background: #f0f2f5;

  .couses-list-inner {
    width: 85%;

    .couses-list-details {
      margin-bottom: 25px;

      .couses-images {
        img {
          height: 255px;
          width: 100%;
          border-radius: 4px 4px 0px 0px;

          @media all and (max-width: 575px) {
            height: 100% !important;
          }
        }
      }

      .recommended-block {
        padding: 24px;
        display: inline-block;
        width: 100%;
        background: #fff;
        min-height: 175px;

        .recommended-block-title {
          h3 {
            font-family: $font-family-outfit;
            font-style: normal;
            font-weight: $font-weight-medium;
            font-size: $font-size-16;
            line-height: 30px;
            color: #1d1d1d;
            margin-bottom: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            margin-bottom: 5px;
          }

          .margin-bottom {
            margin-bottom: 0px !important;
          }
        }

        .recipes-details-title {
          p {
            font-family: $font-family-outfit;
            font-style: normal;
            font-weight: $font-weight-regular;
            font-size: $font-size-14;
            color: #1d1d1d;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 6px;
          }
        }

        .recommended-block-list {
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
            float: left;

            li {
              float: left;
              padding-right: 6px;
              position: relative;
              font-family: $font-family-outfit;
              font-style: normal;
              font-weight: $font-weight-regular;
              font-size: $font-size-14;
              line-height: 18px;
              color: rgba(0, 0, 0, 0.45);
              text-decoration: none;
              border-right: 0px solid rgba(0, 0, 0, 0.25);
              margin-right: 5px;
              margin-bottom: 5px;
            }
          }
        }

        .course-price-details {
          width: 100%;
          margin-top: 8px;
          float: left;
          margin-bottom: 3px;

          .real-and-discounted-price {
            display: flex;
            align-items: center;

            .real-price {
              p {
                font-family: $font-family-outfit;
                font-style: normal;
                font-weight: $font-weight-medium;
                font-size: $font-size-16;
                color: #1d1d1d;
                margin-bottom: 0px;
              }
            }

            .discounted-price {
              p {
                font-family: $font-family-outfit;
                font-style: normal;
                font-weight: $font-weight-regular;
                font-size: $font-size-16;
                color: #5f5f5f;
                margin-bottom: 0px;
                text-decoration: line-through;
                margin-left: 10px;
              }
            }
            .font-size-14 {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }

      .min-height {
        min-height: 0px;
      }
      // .edit-and-delete-button{
      //   width: 100%;
      //   border-top: 2px solid #F0F0F0;
      //   background: #fff;
      //   display: flex;
      //   align-items: center;
      //   border-radius: 0px 0px 4px 4px;
      //   .edit-button{
      //     width: 50%;
      //     border-right: 2px solid #D9D9D9;
      //     text-align: center;
      //     margin-top: 8px;
      //     margin-bottom: 8px;
      //     button{
      //       font-family: $font-family-outfit;
      //         font-style: normal;
      //         font-weight: $font-weight-semi-bold;
      //         font-size: $font-size-16;
      //         color: #1D1D1D;
      //         border: none;
      //         background: transparent;
      //         padding: 3px 65px;
      //     }
      //   }
      //   .delete-button{
      //     width: 50%;
      //     text-align: center;
      //     button{
      //       font-family: $font-family-outfit;
      //         font-style: normal;
      //         font-weight: $font-weight-semi-bold;
      //         font-size: $font-size-16;
      //         color: #F5222D;
      //         border: none;
      //         background: transparent;
      //         padding: 3px 55px;
      //     }
      //   }
      // }
    }
  }

  .load-more-button {
    width: 85%;
    text-align: center;

    button {
      font-family: $font-family-outfit;
      font-style: normal;
      font-weight: $font-weight-regular;
      font-size: $font-size-14;
      color: rgba(0, 0, 0, 0.85);
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
      padding: 5px 15px;
    }
  }
}

.button-main-div {
  width: 600px;

  @media all and (max-width: 980px) {
    width: 500px;
  }
  @media all and (max-width: 600px) {
    width: 400px;
  }
  @media all and (max-width: 500px) {
    width: 318px;
  }
  @media all and (max-width: 400px) {
    width: 261px;
  }
}

.edit-and-delete-button {
  width: 100%;
  border-top: 2px solid #f0f0f0;
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 0px 0px 4px 4px;

  .edit-button {
    width: 50%;
    border-right: 2px solid #d9d9d9;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;

    button {
      font-family: $font-family-outfit;
      font-style: normal;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-16;
      color: #1d1d1d;
      border: none;
      background: transparent;
      //padding: 3px 65px;
    }
  }

  .delete-button {
    width: 50%;
    text-align: center;

    button {
      font-family: $font-family-outfit;
      font-style: normal;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-16;
      color: #f5222d;
      border: none;
      background: transparent;
      //padding: 3px 55px;
    }
  }
}

.common-page-padding {
  overflow-y: scroll;
  height: calc(100vh - 160px);
  overflow-x: hidden;
}

.common-page-padding::-webkit-scrollbar {
  width: 8px;
}

.common-page-padding::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.common-page-padding::-webkit-scrollbar-thumb {
  background: #e16b67;
  border-radius: 10px;
}

.common-page-padding::-webkit-scrollbar-thumb:hover {
  background: #1d1d1d;
}

.common-page-padding {
  padding: 48px;
  margin-top: 160px;
}

@media (max-width: 767px) {
  .common-page-padding {
    padding: 30px;
  }
  .couses-list .couses-list-inner,
  .amazon-products-list .couses-list-inner,
  .offline-class-list .couses-list-inner {
    width: 100%;
  }
  .couses-list .load-more-button,
  .amazon-products-list .load-more-button,
  .offline-class-list .load-more-button {
    width: 100%;
  }
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}

.EditorToolbar__root___3_Aqz {
  position: relative !important;
  z-index: 0 !important;
}

.add-videos-reset-and-done-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 18px;
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #f0f0f0;
  padding-left: 0;

  @media all and (max-width: 388px) {
    padding: 12px 8px;
  }

  .add-video-button {
    button {
      font-family: "Outfit";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      background: #e05955;
      border-radius: 2px;
      border: none;
      padding: 6px 16px;
    }
  }

  .right-button {
    display: flex;
    align-items: center;

    .reset-button {
      button {
        font-family: "Outfit";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #8c8c8c;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        background: transparent;
        margin-right: 8px;
        padding: 6px 16px;
      }
    }

    .done-button {
      button {
        font-family: "Outfit";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        background: #e05955;
        border-radius: 2px;
        border: none;
        padding: 6px 16px;
      }
    }
  }
}

.scroll-list {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 54px;
}

.scroll-list::-webkit-scrollbar {
  width: 8px;
}

.scroll-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.scroll-list::-webkit-scrollbar-thumb {
  background: #e16b67;
  border-radius: 10px;
}

.scroll-list::-webkit-scrollbar-thumb:hover {
  background: #1d1d1d;
}
