.add-images {
  .recipe-uploaded-video-details {
    .row > * {
      padding-right: 7px;
      padding-left: 7px;
    }
    padding: 24px;
    .recipe-uploaded-video-image {
      margin-bottom: 14px;
      img {
        width: 100%;
      }
    }
  }
}

.margin-video {
  margin-bottom: 20px;
}
