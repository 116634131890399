.online-classes {
  .online-preview-lable-and-name {
    p {
      font-family: $font-family-outfit;
      font-style: normal;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-16;
      color: $color-gray;
      margin-bottom: 10px;
    }
    span {
      font-family: $font-family-outfit;
      font-style: normal;
      font-weight: $font-weight-regular;
      font-size: $font-size-16;
      color: $color-gray;
      margin-bottom: 0px;
      margin-left: 10px;
      text-decoration: line-through;
    }
  }
}
