@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

$font-family-outfit: "Outfit";
$font-family-inter: "Inter", sans-serif;

$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-dark: 900;

$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-34: 34px;
$font-size-35: 35px;
$font-size-40: 40px;
$font-size-48: 48px;

$color-black: #000000;
$color-red: #e05955;
$color-white: #ffffff;
$color-dark: #1d1d1d;
$color-gray: #434343;
$color-light-gray: #8c8c8c;
$color-medium-dark: #5f5f5f;
$color-white-tone: #f4f4f4;
