.add-category {
  background: #f0f2f5;
  .add-category-details {
    background: #ffffff;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    width: 50%;
    &:last-child {
      width: 100%;
    }
    .add-category-and-close-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      width: 100%;
      border-bottom: 1px solid #f0f0f0;
      .add-category-title {
        h1 {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-dark;
          font-size: $font-size-16;
          color: $color-gray;
          margin-bottom: 0px;
        }
      }
      .close-button {
        button {
          border: none;
          background: transparent;
          height: 10px;
          padding: 0px;
        }
        img {
          height: 10px;
          width: 10px;
        }
      }
    }

    @media all and (max-width: 980px) {
      width: 100% !important;
    }
  }
  .category-input-feild {
    padding: 20px 24px;
    border-bottom: 1px solid #f0f0f0;
    .category-input-feild-inner {
      label {
        font-family: $font-family-outfit;
        font-style: normal;
        font-weight: $font-weight-regular;
        font-size: $font-size-14;
        color: $color-gray;
        margin-bottom: 10px;
      }
      input {
        font-family: $font-family-outfit;
        font-style: normal;
        font-weight: $font-weight-regular;
        font-size: $font-size-14;
        width: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        padding: 8px;
        &::placeholder {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-14;
          color: $color-light-gray;
        }
      }
    }
  }
  .reset-and-done-button {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 12px 18px;
    width: 100%;
    .reset-button {
      button {
        font-family: $font-family-outfit;
        font-style: normal;
        font-weight: $font-weight-regular;
        font-size: $font-size-14;
        color: $color-light-gray;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        background: transparent;
        margin-right: 8px;
        padding: 6px 16px;
      }
    }
    .done-button {
      button {
        font-family: $font-family-outfit;
        font-style: normal;
        font-weight: $font-weight-regular;
        font-size: $font-size-14;
        color: $color-white;
        background: #e05955;
        border-radius: 2px;
        border: none;
        padding: 6px 16px;
      }
    }
  }
  .category-list {
    margin-top: 25px;
    .category-list-details {
      padding: 25px;
      border-bottom: 1px solid #f0f0f0;
      .category-list-wise-button {
        button {
          font-family: $font-family-outfit;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: $font-size-14;
          color: $color-medium-dark;
          border: none;
          background: $color-white-tone;
          padding: 10px 10px;
          margin-bottom: 10px;
        }
        img {
          margin-left: 10px;
          height: 9px;
        }
      }
    }
  }
}

.loading_message {
  text-align: center;
  padding: 68px;
  font-size: 23px;
}

.mt-200 {
  margin-top: 200px;
}
